
import { Component, Vue, } from 'vue-property-decorator'
import { mapState, mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { FoldersInterface, } from '@/store/types/Dataroom/Folder'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'

import { BreadcrumbInterface, } from '@/store/types/Polimorfic/BreadCrumbs'

import CreateDialog from '@/components/CreateFolder/CreateFolder.vue'
import TableEmptyState from '@/components/TableEmptyState/Index.vue'
import Unauthorized from '@/components/Unauthorized/index.vue';
import router from '@/router'
@Component({
  components: {
    CreateDialog,
    TableEmptyState,OverlayBackground, Unauthorized,
  },
  computed: {
    ...mapState('BusinessModule', ['BusinessState',]),
    ...mapGetters('BusinessModule', ['getSelectedProduct',]),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role;
      return !role.some((role: any) => role.includes("Cliente") || role.includes("Dataroom") || role.includes("Implementador"));
    },
  },
})
export default class ClientsComponent extends Vue {
  private folderSelected = {}
  private editFolder = false
  private BreadCrumbs: BreadcrumbInterface[] = []
  private loaderUpload = false
  private dialogFolder = false
  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private permission: string | undefined = ''
  private fileToUpload: FileUploadInterface[] = []
  private folderId = this.$store.state.AuthModule.AuthState.companySelected.folder.id
  private folderPermission = ''
  private allFilesFolders = this.$store.state.DataRoomModule.DataRoomState.filesfolders
  private loading = false;
  private unauthorized = false
  mounted () {    
    this.$mixpanel.track('Dataroom')
    this.$store.state.AuthModule.AuthState.role.includes('Implementador') || this.$store.state.AuthModule.AuthState.role.includes('Customer Success')
      ? (this.folderId = this.$store.state.AuthModule.AuthState.companySelected.folder.id)
      : (this.folderId = this.$store.state.AuthModule.AuthState.folder_id)
    this.BreadCrumbs.push({
      id: this.folderId,
      name: this.$store.state.AuthModule.AuthState.companySelected.name,
    })
    this.getFolders()
  }

  private goToFolder (tr: FoldersInterface) {
    if (tr.extension) {
      return
    }

    this.folderSelected = tr
    this.folderId = tr.id
    this.permission = tr.permission
    this.BreadCrumbs.push({
      id: tr.id,
      name: tr.name,
    })
    this.getFolders()
    this.$mixpanel.track(tr.name)
  }

  private goToFolderBreadcrumb (tr: BreadcrumbInterface, index: number) {
    if (this.BreadCrumbs.length === index + 1) return

    this.folderId = this.BreadCrumbs[index].id
    this.BreadCrumbs.splice(index + 1)
    this.getFolders()
  }

  private deleteItem (data: any) {
    let elementName = 'carpeta'
    let elementStore = 'DataRoomModule/deleteFolder'

    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED_DELETE', this.folderId)

    if (data.extension) {
      elementName = 'archivo'
      elementStore = 'DataRoomModule/deleteFile'
    }

    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: `Por favor espere, Eliminando ${elementName}`,
    })
    this.$store.dispatch(`${elementStore}`, data.id).then(() => {
      this.loaderUpload = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: `Éxito, Eliminando ${elementName.charAt(0).toUpperCase()}${elementName.substring(
          1
        )}`,
      })
      this.getFolders()
      if (elementName == 'archivo') {
        this.$mixpanel.track('Archivo Eliminado', {
          Archivo: data.name,
          Lugar: this.folderSelected,
        })
      } else {
        this.$mixpanel.track('Carpeta Eliminada', {
          Carpeta: data.name,
          Lugar: this.folderSelected,
        })
      }
    })
  }

  private getFolders () {    
    this.loading = true
    this.$store.commit('DataRoomModule/INITIAL_STATE')
    this.$store
      .dispatch('DataRoomModule/getFolders', this.folderId)
      .then((response) => {
        this.folderPermission = response.data.data.permission
        this.allFilesFolders = [...response.data.data.folders, ...response.data.data.files,]
        this.$store.commit('SearchModule/SET_SEARCH_RESULTS', this.allFilesFolders)
        this.dialogFolder = false
        this.unauthorized = false
        this.loading = false
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.unauthorized = true;
        }
        if (error.response.status === 401) {
          router.push('/')
          location.reload()
        }
        this.loading = false
      })
  }

  private readbase64 () {
    const refFiles: any = this.$refs.filesData
    const reader = new FileReader()
    reader.readAsDataURL(refFiles[0].files[0])
    reader.onload = () => {
      const fileAux: string = refFiles[0].files[0].name.toLowerCase()
      if (
        fileAux.split('.').pop() === 'txt' ||
        fileAux.split('.').pop() === 'pdf' ||
        fileAux.split('.').pop() === 'doc' ||
        fileAux.split('.').pop() === 'docx' ||
        fileAux.split('.').pop() === 'xls' ||
        fileAux.split('.').pop() === 'xlsx' ||
        fileAux.split('.').pop() === 'ppt' ||
        fileAux.split('.').pop() === 'pptx' ||
        fileAux.split('.').pop() === 'jpg' ||
        fileAux.split('.').pop() === 'jpeg' ||
        fileAux.split('.').pop() === 'png' ||
        fileAux.split('.').pop() === 'gif'
      ) {
        this.dataroomsName.push(fileAux)
        const readerResult: string | undefined = reader?.result?.toString()
        if (readerResult) {
          this.datarooms.push(readerResult)
        }
        this.$mixpanel.track('Archivos subidos', { 'Nombre Archivo': fileAux, })
        this.uploadFile()
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: 'Error, Tipo de archivo no permitido',
          color_notification: 'danger',
        })
        return
      }
    }
  }

  private resetVars () {
    this.dataroomsName = []
    this.datarooms = []
    this.fileToUpload = []
  }

  uploadFile () {
    this.loaderUpload = true
    this.allFilesFolders.push({
      name: this.dataroomsName[0],
      extension: this.dataroomsName[0].split('.').pop(), // to be improved extension detection
    })
    this.fileToUpload.push({
      filename: this.dataroomsName[0],
      data: this.datarooms[0],
    })
    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED', this.folderId)
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Subiendo archivo',
    })
    this.$store.dispatch('DataRoomModule/uploadFile', this.fileToUpload).then((response) => {
      this.$store.commit('DataRoomModule/SET_USER_FILES', response.data.folder.files)
      this.loaderUpload = false
      this.resetVars()
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Archivo subido',
      })
      this.$emit('close')
    })
  }

  downloadFile (data: any) {
    this.$mixpanel.track('Archivo descargado Dataroom', { Archivo: data.name, })
    window.open(data.url, '_blank')
  }

  private createFolderAPI (template: string) {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Porfavor espere, Creando carpeta',
    })
    this.$store.commit('DataRoomModule/SET_FOLDER_SELECTED', this.folderId)
    this.$store.dispatch('DataRoomModule/createFolder', template).then(() => {
      this.loaderUpload = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Carpeta creada',
      })
      this.$emit('close')
      this.getFolders()
      this.$mixpanel.track('Carpeta Creada', {
        'En Carpeta': this.folderSelected,
        'Carpeta Nueva': template,
      })
    })
  }

  private createFolder () {
    this.editFolder = false
    this.dialogFolder = true
  }

  private editFolderData (data: FoldersInterface) {
    this.folderSelected = data
    this.editFolder = true
    this.dialogFolder = true
  }

  private closeDialogFolder () {
    this.dialogFolder = false
  }
}
