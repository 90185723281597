
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'
import { mapActions, } from 'vuex'

import { FoldersInterface, } from '@/store/types/Dataroom/Folder'


@Component({
  computed: {
    ...mapActions('DataRoomModule', ['editFolder',]),
  },
})
export default class CreateFolderDialogComponent extends Vue {
  @Prop({ required: true, }) readonly showDialog!: string
  @Prop({ required: false, default: false, }) readonly edit!: boolean
  @Prop({ required: true, default: false, })
  readonly folderSelected!: FoldersInterface
  private dialog = false
  private name = ''

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    }
    if (this.edit) {
      this.name = this.folderSelected.name
    } else {
      this.name = ''
    }
  }
  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }
  private resetVars () {
    this.name = ''
  }
  private editEvent () {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Por favor espere, Editando carpeta',
    })
    this.folderSelected.name = this.name
    this.$store.dispatch('DataRoomModule/editFolder', this.folderSelected).then(() => {
      this.dialog = false
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Carpeta Editada',
      })
      this.$emit('refresh')
    })
  }
  private emitEvent () {
    this.dialog = false
    this.$emit('action', this.name)
  }
}
