<template>
    <div class="unauthorized-container">
      <img src="@/assets/icons/unathorize.svg" class="unathorizelogo" alt="">
      <h1 class="unauthorized-title">Acceso denegado</h1>
      <p class="unauthorized-text">No tienes permiso de acceso para</p>
      <p class="unauthorized-text">visualizar la información de este módulo</p>
    </div>
</template>
  
<script>
import { Vue } from "vue-property-decorator";
export default class Unauthorized extends Vue { }
</script>
<style lang="scss" scoped>
.unauthorized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  margin-right: 13%; 
  text-align: center;
}
.unathorizelogo {
  width: 250px;
  color: #87e5e8;
}
.unauthorized-title {
  margin: 30px 0 15px 0;
  font-size: 26px;
  font-weight: 700;
}
.unauthorized-text {
  margin: 5px 0;
  font-size: 19px;
  font-weight: 400;
}
</style>