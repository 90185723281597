
import { Component, Vue, } from "vue-property-decorator";

@Component
export default class SpinnerLoader extends Vue {

  public currentIndex = 0;
  public imageCount = 21;

  mounted() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % 21;
    }, 60);
  }
}
