

import { Component, Vue } from "vue-property-decorator";
import SpinnerLoader from "@/components/Spinner/Index.vue";


@Component({
  components: { SpinnerLoader },
  
})
export default class OverlayBackground extends Vue { }

